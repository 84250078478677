import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`8:00 EMOM of:`}</p>
    <p>{`1 Pistol per leg`}</p>
    <p>{`2 Jumping Step Ups per leg`}</p>
    <p>{`4 Jumping Goblet Squats (53/35) (RX+ 70/53)`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 10-Back Squats (225/155)(RX+ 275/185)`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`15-Burpees Over Rower`}</p>
    <p>{`Cash Out: 10-Back Squats (225/155)(RX+ 275/185)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/23/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      